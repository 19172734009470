/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuth } from "@with-nx/auth";
import { useEffect, useState } from "react";

import useService from "./useService";

export type UseEcommerceOrderStatuses =
  | "AWAITING_PAYMENT"
  | "AWAITING_APPROVAL"
  | "PARTIALLY_DELIVERED"
  | "AWAITING_LICENSOR"
  | "LICENSOR_APPROVED"
  | "AWAITING_RETURN"
  | "FINALIZED"
  | "CANCELLED";
export type UseEcommerceOrderItemStatuses =
  | "AWAITING_PAYMENT"
  | "AWAITING_APPROVAL"
  | "PROCESSING"
  | "DELIVERED"
  | "IN_TRANSIT"
  | "AWAITING_RETURN"
  | "DEMO_MODE"
  | "FINALIZED"
  | "CANCELLED";

export type UseEcommerceOrderPaymentMethods =
  | "PURCHASE_ORDER"
  | "CREDIT_CARD"
  | "ROYALTIES_ONLY";

export const UseEcommerceOrdersStatusLabels: {
  [key: string]: string;
} = {
  AWAITING_PAYMENT: "Awaiting Payment",
  AWAITING_APPROVAL: "Awaiting Approval",
  PARTIALLY_DELIVERED: "Partially Delivered",
  AWAITING_RETURN: "Awaiting Return",
  FINALIZED: "Finalized",
  AWAITING_LICENSOR: "Awaiting Reseller",
  LICENSOR_APPROVED: "Reseller Approved",
  CANCELLED: "Cancelled",
};

type UseEcommerceFreightSubtotal = {
  freight: string;
  rushFee: string;
  subtotal: string;
  available: boolean;
  expeditedFee: string;
};

type UseEcommerceSubtotal = {
  freight: UseEcommerceFreightSubtotal;
  products: string;
  subtotal: string;
  taxInclusive: string;
  taxNonInclusive: string;
};

type UseEcommerceProduction = {
  id: string | number;
  showTitle: string;
  organizationName: string;
  firstPerformance: string;
  lastPerformance: string;
  additionalWeeks: number;
  licensorCode?: string;
};

type UseEcommerceOrderItemShowBrand = {
  id: string | number;
  name: string;
  createdAt: string;
  logoColor: string;
  updatedAt: string;
  dropsCount: number;
};

type UseEcommerceOrderItemShow = {
  id: string | number;
  slug: string;
  title: string;
  public: boolean;
  createdAt: string;
  logoColor: string;
  showBrand: UseEcommerceOrderItemShowBrand;
  updatedAt: string;
  attachments: unknown[];
};

type UseEcommerceOrderItemTypes =
  | "SCENIC_PROJECTION_PACKAGE"
  | "CHOREO_GUIDE"
  | "DIGITAL_DROP"
  | "PROJECTOR"
  | "STAGE_GEAR"
  | "AD_HOC";

type UseEcommerceOrderItemScenicProjection = {
  id: string | number;
  name: string;
  show: UseEcommerceOrderItemShow;
  public: boolean;
  approved: boolean;
  fileSize: string;
  createdAt: string;
  updatedAt: string;
  attachments?: unknown[];
};

type UseEcommerceOrderItemChoreoGuide = {
  id: string | number;
  show: UseEcommerceOrderItemShow;
  producer: string;
  createdAt: string;
  updatedAt: string;
  attachments?: unknown[];
  otherCredits: string;
  choreographer: {
    id: string | number;
    name: string;
    biography: string;
    createdAt: string;
    updatedAt: string;
    attachments?: unknown[];
  };
  marketingDescription: string;
};

type UseEcommerceOrderItemDigitalDrop = {
  id: string | number;
  name: string;
  type: string;
  cueDescription: string;
  cueLine?: string;
  cuePageNumber?: string;
  public: boolean;
  featuredRank?: number;
  userStarsCount: number;
  createdAt: string;
  updatedAt: string;
  attachments?: unknown[];
  tags: {
    id: string | number;
    name: string;
    category?: string;
    createdAt: string;
    updatedAt: string;
    digitalDrops?: unknown[];
  }[];
  themes: {
    id: string | number;
    name: string;
    createdAt: string;
    updatedAt: string;
    digitalDrops?: unknown[];
  }[];
};

export type UseEcommerceOrderItem = {
  id: string | number;
  status: UseEcommerceOrderItemStatuses;
  product: {
    id: string | number;
    type: UseEcommerceOrderItemTypes;
    enabled: boolean;
    scenicLicensors?: unknown[];
    choreoLicensors?: unknown[];
    scenicVariation?: string;
    choreoVariation?: string;
    scenicProjection?: UseEcommerceOrderItemScenicProjection;
    choreoGuide?: UseEcommerceOrderItemChoreoGuide;
    digitalDrop?: UseEcommerceOrderItemDigitalDrop;
    gearResource?: {
      id: string;
      name: string;
    };
  };
  subtotal: UseEcommerceSubtotal;
  production: UseEcommerceProduction;
  rental?: {
    id: string;
    rentalEnd?: string;
    rentalStart?: string;
  };
  rentalEnd?: string;
  rentalStart?: string;
  createdAt?: string;
  proLicense?: boolean;
  payments?: unknown[];
};

export type UseEcommerceOrdersOrder = {
  id: string | number;
  status: UseEcommerceOrderStatuses;
  currency: string;
  currencySymbol: string;
  shippingAddressSnapshot: unknown;
  billingAddressSnapshot: unknown;
  subtotalLicensed: UseEcommerceSubtotal;
  subtotalNonLicensed: UseEcommerceSubtotal;
  freight: UseEcommerceFreightSubtotal;
  totalInclusiveTaxes: string;
  totalNonInclusiveTaxes: string;
  total: string;
  paymentMethod: UseEcommerceOrderPaymentMethods;
  createdAt: string;
  updatedAt: string;
  region: unknown;
  user: unknown;
  attachments: unknown[];
  items: UseEcommerceOrderItem[];
  payments: unknown[];
};

export const useEcommerceOrders = () => {
  const [orders, _orders] = useState<UseEcommerceOrdersOrder[]>([]);
  const [loading, _loading] = useState<boolean>(true);
  const [error, _error] = useState<boolean>(true);
  const auth = useAuth();

  const getOrders = async () => {
    return await makeRequestToMicroservice(
      "GET",
      "/ecommerce/orders",
      {
        "pagination[page]": "1",
        "pagination[pageSize]": "50",
        "pagination[orderBy][createdAt]": "DESC",
        "includes[description]": "true",
      },
      {
        Authorization: `Bearer ${auth.authState.session?.token}`,
      }
    );
  };

  const makeRequestToMicroservice = useService("microservice", {
    cache: 10_000,
  });

  const fetch = async () => {
    try {
      _loading(true);
      _error(false);

      const request = (await getOrders()) as any;

      if (request.result) {
        _orders(request.result);
      }
    } catch (error) {
      _error(true);
    } finally {
      _loading(false);
    }
  };

  useEffect(() => {
    if (auth.authState.status !== "authenticated") {
      return;
    }

    fetch();
  }, [auth.authState.status]);

  const items = orders
    .map((order) => {
      return (
        order?.items?.map((item) => {
          return {
            ...item,
            createdAt: order.createdAt,
            orderId: order.id,
            payments: order?.payments || [],
          };
        }) || []
      );
    })
    .flat()
    .filter((item) => item.product.type !== "AD_HOC");

  const active = items.filter((item) => {
    if (item.product.type === "DIGITAL_DROP") {
      if (
        item.status === "AWAITING_APPROVAL" ||
        item.status === "AWAITING_PAYMENT"
      ) {
        return false;
      }
      return true;
    } else if (item.product.type === "PROJECTOR") {
      return item.status === "DELIVERED";
    } else {
      return (
        item.status === "DELIVERED" ||
        item.status === "AWAITING_RETURN" ||
        item.status === "FINALIZED"
      );
    }
  });

  const pending = items.filter((item) => {
    if (item.product.type === "DIGITAL_DROP") {
      if (
        item.status === "AWAITING_APPROVAL" ||
        item.status === "AWAITING_PAYMENT"
      ) {
        return true;
      }
      return false;
    } else if (item.product.type === "PROJECTOR") {
      return item.status !== "DELIVERED";
    } else {
      return (
        item.status !== "DELIVERED" &&
        item.status !== "AWAITING_RETURN" &&
        item.status !== "FINALIZED"
      );
    }
  });

  return { orders, items, active, pending, loading, error, reload: fetch };
};
